<script setup lang="ts"></script>

<template>
  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container">
        <div class="fr-header__body-row">
          <div class="fr-header__brand fr-enlarge-link">
            <div class="fr-header__brand-top">
              <div class="fr-header__logo">
                <a href="/" title="Accueil - FiligraneFacile">
                  <p class="fr-logo">
                    République
                    <br />Française
                  </p>
                </a>
              </div>
            </div>
            <div class="fr-header__service">
              <p class="fr-header__service-title">
                <span style="font-weight: normal">Filigrane</span>Facile
                <span class="fr-badge fr-badge--sm fr-badge--green-menthe">BÊTA</span>
              </p>
            </div>
          </div>
          <div class="fr-header__tools">
            <div class="fr-header__tools-links">
              <ul class="fr-btns-group">
                <li>
                  <a
                    class="fr-btn fr-icon-add-circle-line"
                    href="https://www.dossierfacile.logement.gouv.fr/?mtm_campaign=filigranefacile"
                    target="_blank"
                  >
                    Vous constituez un dossier de location ? Essayez DossierFacile
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
