<script setup lang="ts"></script>

<template>
  <section class="fr-my-4w">
    <article>
      <div class="fr-container">
        <div class="fr-grid-row justify-content-center">
          <div class="fr-col-md-10 fr-col-lg-8">
            <h1>Déclaration d’accessibilité</h1>
            <p>
              FiligraneFacile s’engage à rendre ses sites internet, intranet, extranet et ses
              progiciels accessibles (et ses applications mobiles et mobilier urbain numérique)
              conformément à l’article 47 de la loi n°2005-102 du 11 février 2005.
            </p>
            <p>
              À cette fin, nous mettons en œuvre la stratégie et les actions suivantes :
              <a href="https://www.numerique.gouv.fr/uploads/DINUM_SchemaPluriannuel_2020.pdf"
                >Télécharger le schéma pluriannuel de la DINUM au format PDF</a
              >.
            </p>
            <p>
              Cette déclaration d’accessibilité s’applique à
              <strong>FiligraneFacile</strong> (https://filigrane.beta.gouv.fr).
            </p>

            <h2>État de conformité</h2>
            <p>
              <strong>FiligraneFacile</strong> est <strong>non conforme</strong> avec le référentiel
              général d’amélioration de l’accessibilité (RGAA) 4.1. Le site n’a pas encore été
              audité.
            </p>

            <h2>Établissement de cette déclaration</h2>
            <p>Cette déclaration a été établie le 19 septembre 2023.</p>

            <h2>Retour d'information et contact</h2>
            <p>
              Si vous n’arrivez pas à accéder à un contenu ou à un service, vous pouvez contacter le
              responsable du site internet pour être orienté vers une alternative accessible ou
              obtenir le contenu sous une autre forme.
            </p>
            <p>
              Contacter :<br />
              <span class="text-bold">
                Ministère de la Transition écologique,<br />
                Direction générale de l’Aménagement, du Logement et de la Nature,<br />
                Direction de l’habitat, de l’urbanisme et des paysages,<br />
                Service DossierFacile
              </span>
            </p>

            <p>
              1 place Carpeaux<br />
              92800 Puteaux<br />
              France
            </p>
            <p>+33 1 40 81 21 22</p>

            <h2>Voie de recours</h2>
            <p>
              Cette procédure est à utiliser dans le cas suivant&nbsp;: vous avez signalé au
              responsable du site internet un défaut d’accessibilité qui vous empêche d’accéder à un
              contenu ou à un des services du portail et vous n’avez pas obtenu de réponse
              satisfaisante.
            </p>
            <p>Vous pouvez&nbsp;:</p>
            <ul>
              <li>
                Écrire un message au
                <a href="https://formulaire.defenseurdesdroits.fr/">Défenseur des droits</a>
              </li>
              <li>
                Contacter
                <a href="https://www.defenseurdesdroits.fr/saisir/delegues"
                  >le délégué du Défenseur des droits dans votre région</a
                >
              </li>
              <li>
                Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre)&nbsp;:<br />
                Défenseur des droits<br />
                Libre réponse 71120<br />
                75342 Paris CEDEX 07
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>
