{
  "watermarking-page": {
    "title": "Ajoutez un filigrane à n'importe quel document",
    "behaviour-1": "Le fichier filigrané sera au format PDF.",
    "behaviour-2": "Les fichiers originaux seront <strong>effacés de nos serveurs après la génération</strong> du fichier filigrané.",
    "behaviour-3": "Le fichier filigrané sera <strong>effacé de nos serveurs après le premier téléchargement</strong> (ou au bout d'un jour s'il n'y a pas eu de téléchargement).",
    "add-file-label": "Sélectionner des fichiers :",
    "supported-file-format": "Formats supportés : jpg, png, heic, pdf.",
    "submit": "Ajouter le filigrane",
    "subtitle-cnil": "L’ajout d’un filigrane sur vos documents est une bonne pratique mais certains organismes peuvent avoir besoin d’un document vierge (banques, services publics, etc.)",
    "wait": "Veuillez patienter",
    "add-watermark": {
      "label": "Indiquez le filigrane à insérer :",
      "placeholder": "Ex : Document exclusivement destiné à la location immobilière"
    },
    "upload-error": "Une erreur est survenue lors de l'envoi des fichiers. Veuillez réessayer.",
    "download-file": "Télécharger le document filigrané"
  },
  "feedback": {
    "like": "J'ai aimé ce service",
    "dislike": "Vous pouvez mieux faire",
    "feedback-registered": "Merci pour votre retour",
    "tweet-content": "Usurpation d’identité, escroqueries, interdit bancaire...\u000aDécouvrez Filigrane Facile, le site de l’État qui protège vos documents de toute réutilisation frauduleuse : https://filigrane.beta.gouv.fr\u000a#FiligraneFacile #BetaGouv",
    "share-on-twitter": "Partager sur twitter"
  }
}