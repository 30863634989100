<script setup lang="ts"></script>

<template>
  <h1 class="fr-h2">Mentions légales de la Plateforme FiligraneFacile</h1>

  <h2 class="fr-h5">Editeur de la Plateforme</h2>
  <p>
    La Plateforme « FiligraneFacile » est éditée par :<br />
    La Direction générale de l’aménagement, du logement et de la nature (DGALN)
    située :<br />
    Tour Séquoia<br />
    1 place Carpeaux<br />
    92800 Puteaux<br />
    France<br />
    Téléphone : 01 40 81 21 22
  </p>

  <h2 class="fr-h5">Directeur de la publication</h2>
  <p>
    Le Directeur de la publication est Monsieur Philippe MAZENC, directeur
    général de l’aménagement, du logement et de la nature.
  </p>

  <h2 class="fr-h5">Hébergement de la Plateforme</h2>
  <p>
    Cette Plateforme est hébergée par :<br />
    OVH<br />
    2, rue Kellermann<br />
    59100 Roubaix<br />
    France
  </p>

  <h2 class="fr-h5">Accessibilité</h2>
  <p>
    La conformité aux normes d’accessibilité numérique est un objectif ultérieur
    mais nous tâchons de rendre cette plateforme accessible à toutes et à tous.
  </p>

  <h2 class="fr-h5">En savoir plus</h2>
  <p>
    Pour en savoir plus sur la politique d’accessibilité numérique de l’État :
    <a href="https://accessibilite.numerique.gouv.fr" rel="noopener"
      >https://accessibilite.numerique.gouv.fr</a
    >
  </p>

  <h2 class="fr-h5">Signaler un dysfonctionnement</h2>
  <p>
    Si vous rencontrez un défaut d’accessibilité vous empêchant d’accéder à un
    contenu ou une fonctionnalité de la plateforme, merci de nous en faire part
    : <a href="mailto:contact@filigrane.beta.gouv.fr">contact@filigrane.beta.gouv.fr</a>.
  </p>
  <p>
    Si vous n’obtenez pas de réponse rapide de notre part, vous êtes en droit de
    faire parvenir vos doléances ou une demande de saisine au Défenseur des
    droits.
  </p>

  <h2 class="fr-h5">Sécurité</h2>
  <p>
    La plateforme est protégée par un certificat électronique, matérialisé pour
    la grande majorité des navigateurs par un cadenas. Cette protection
    participe à la confidentialité des échanges.
  </p>
  <p>
    En aucun cas les services associés à la plateforme ne seront à l’origine
    d’envoi de courriels pour demander la saisie d’informations personnelles.
  </p>
</template>

<style scoped lang="scss"></style>
