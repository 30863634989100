<script setup lang="ts"></script>

<template>
  <h1 class="fr-h2">Conditions générales d’utilisation de FiligraneFacile</h1>

  <p>
    Les présentes conditions générales d’utilisation (dites « CGU ») fixent le cadre juridique de «
    FiligraneFacile » et définissent les conditions d’accès et d’utilisation des services par
    l’Utilisateur.
  </p>

  <h2 class="fr-h5">Article 1 - Champ d’application</h2>
  <p>
    L'utilisation du service « FiligraneFacile » est gratuite et réservée aux Utilisateurs qui
    souhaitent ajouter un filigrane à n’importe quel document.
  </p>

  <h2 class="fr-h5">Article 2 – Objet</h2>
  <p>
    « FiligraneFacile » est un service numérique qui permet d’ajouter un filigrane à n’importe quel
    document. Le fichier filigrané sera au format PDF.
  </p>

  <h2 class="fr-h5">Article 3 – Définitions</h2>
  <p>
    « L'Utilisateur » est toute personne ajoutant un filigrane à un ou plusieurs documents. Les «
    Services » sont les fonctionnalités offertes par la plateforme pour répondre à ses finalités.
  </p>

  <h2 class="fr-h5">Article 4 - Fonctionnalités</h2>
  <p>
    Chaque Utilisateur peut sélectionner un fichier pour y ajouter un filigrane. Les formats
    supportés des fichiers sont les suivants : jpg, png, heic et pdf.
  </p>
  <p>
    Chaque Utilisateur sélectionne son fichier puis indique le texte à insérer pour pouvoir ajouter
    le filigrane. L’Utilisateur télécharge le document filigrané puis récupère une copie de son
    document au format PDF avec le filigrane apposé.
  </p>
  <p>
    L'Utilisateur n'est pas limité par le nombre de fois qu'il peut utiliser les Services. L'ajout
    de filigranes s'effectue cependant fichier après fichier.
  </p>

  <h2 class="fr-h5">Article 5 - Responsabilités</h2>

  <h3 class="fr-h6">5.1 L’Éditeur de « FiligraneFacile »</h3>
  <p>
    Les sources des informations diffusées sur le site sont réputées fiables mais le site ne
    garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
  </p>
  <p>
    L’Éditeur s’engage à la sécurisation du site, notamment en prenant toutes les mesures
    nécessaires permettant de garantir la sécurité et la confidentialité des informations fournies.
  </p>
  <p>
    L’Éditeur fournit les moyens nécessaires et raisonnables pour assurer un accès continu au site.
    Il se réserve la liberté de faire évoluer, de modifier ou de suspendre, sans préavis, le site
    pour des raisons de maintenance ou pour tout autre motif jugé nécessaire.
  </p>
  <p>
    L’Éditeur s’engage à ce que les fichiers originaux soient effacés après la génération du fichier
    filigrané. Le fichier filigrané est effacé après le premier téléchargement ou un jour après si
    le téléchargement n’a pas eu lieu.
  </p>
  <p>
    L’Éditeur rappelle que « FiligraneFacile » ne garantit pas l’authenticité du document présenté.
  </p>

  <h3 class="fr-h6">5.2 L’Utilisateur</h3>
  <p>
    L'Utilisateur s’engage à communiquer des données strictement nécessaires à sa demande. Il veille
    particulièrement aux données sensibles notamment les données relatives aux opinions
    philosophiques, syndicales et religieuses.
  </p>

  <h2 class="fr-h5">Article 6 - Mise à jour des conditions générales d’utilisation</h2>
  <p>
    Les termes des présentes conditions générales d’utilisation peuvent être amendés à tout moment,
    en fonction des modifications apportées à la plateforme, de l’évolution de la législation ou
    pour tout autre motif jugé nécessaire. Chaque modification donne lieu à une nouvelle version qui
    est acceptée par les parties.
  </p>
</template>

<style scoped lang="scss"></style>
